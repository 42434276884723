import { useEffect, useState } from 'react';
import './App.css';
import Spinner from '@atlaskit/spinner';
import GettingStarted from './components/gettingStarted';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [domain, setDomain] = useState('');

  useEffect(() => {
    setLoading(false);
    // window.AP.getLocation((location) => {
    //   let domain = new URL(location);
    //   setDomain(domain.origin);
    //   setLoading(false);
    // });
  }, []);

  return loading ? (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      <Spinner />
    </div>
  ) : (
    <GettingStarted domain={domain} />
  );
};

export default App;
