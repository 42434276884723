import logo from '../assets/logo.png';
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import CheckCircleSvg from '../assets/check-circle.svg';
import PersonSvg from '../assets/person.svg';
import PeopleSvg from '../assets/people-group.svg';
import ProjectsSvg from '../assets/folder-filled.svg';
import OfficeSvg from '../assets/office-building.svg';
import DocumentationSvg from '../assets/documentation-button.svg';
import FooterLogo from '../assets/footer-logo.png';
import CalendarSvg from '../assets/calendar.svg';
import DocumentIcon from '@atlaskit/icon/glyph/document';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import Button from '@atlaskit/button';
import OpenIcon from '@atlaskit/icon/glyph/open';
const GettingStarted = (props) => {
  return (
    <div>
      <div className='main'>
        <div className='header'>
          <div className='page-title'>
            <img src={logo} className='logo' />
            <span>User activity audit log</span>
          </div>
          <div className='top-menu'>
            <a
              target='_blank'
              href='https://twinit.atlassian.net/wiki/spaces/UAAL/overview'
              className='header-button'
              rel='noreferrer'
            >
              <DocumentIcon size='medium' />
              <span>Documentation</span>
            </a>
            <a
              target='_blank'
              href='https://marketplace.atlassian.com/apps/1230734/user-activity-audit-log?tab=reviews&hosting=cloud'
              className='header-button'
              rel='noreferrer'
            >
              <FeedbackIcon size='medium' />
              <span>Feedback</span>
            </a>
            <a
              target='_blank'
              href='https://twinit.atlassian.net/servicedesk/customer/portal/3/group/17/create/516'
              className='header-button'
              rel='noreferrer'
            >
              <CommentIcon size='medium' />
              <span>Support</span>
            </a>
          </div>
        </div>
        <div className='app'>
          <div className='app-section'>
            <div className='app-section-title'>
              <h1>Getting Started</h1>
            </div>
            <span className='app-section-description'>
              Thank you for your interest in User Activity Audit Log plugin. This app gives you the
              possibility to search, filter, and view a detailed log of user activities across your
              cloud instance.
            </span>
            <div className='app-section-images'>
              <div className='app-section-image'>
                <img src={image1} alt='Search with JQL support and ability to save filters' />
                <span>Search with JQL support and ability to save filters </span>
              </div>
              <div className='app-section-image'>
                <img
                  src={image2}
                  alt='Results: aggregated and detailed table views, 30+ actions, linked issues'
                />
                <span>
                  Results: aggregated and detailed table views, 30+ actions, linked issues.
                </span>
              </div>
            </div>
          </div>
          <div className='app-section'>
            <div className='app-section-title'>
              <h1>Features</h1>
            </div>
            <span className='app-section-description'>
              With the User Activity Audit Log you can:
            </span>
            <div className='features-list'>
              <div>
                <ul className='features-list' data-indent-level='1'>
                  <li>
                    <div className='li-item'>
                      <img src={CheckCircleSvg} alt='Generate users activity log' /> Generate users'
                      activity log
                    </div>
                  </li>
                  <li>
                    <div className='li-item'>
                      <img
                        src={CheckCircleSvg}
                        alt='Easily Search and filter activities based on'
                      />
                      Easily Search and filter activities based on:
                    </div>
                    <ul>
                      <li>
                        <div className='li-item'>
                          <img src={PersonSvg} alt='Users' />
                          Users
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={PeopleSvg} alt='User Groups' />
                          User Groups
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={ProjectsSvg} alt='Projects' />
                          Projects
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={OfficeSvg} alt='Organizations' />
                          Organizations
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={CalendarSvg} alt='Date Range' />
                          Date Range
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className='li-item'>
                      <img src={CheckCircleSvg} alt='Enhance search and filters with JQL' />
                      Enhance search and filters with JQL
                    </div>
                  </li>
                  <li>
                    <div className='li-item'>
                      <img src={CheckCircleSvg} alt='Save, reuse, share, and manage filters' />
                      Save, reuse, share, and manage filters
                    </div>
                  </li>
                  <li>
                    <div className='li-item'>
                      <img
                        src={CheckCircleSvg}
                        alt=' View a cumulative number of actions made by the user for'
                      />
                      View a cumulative number of actions made by the user for:
                    </div>
                    <ul>
                      <li>
                        <div className='li-item'>
                          <img src={CheckCircleSvg} alt='Save, reuse, share, and manage filters' />
                          Issues: created, updated, deleted, status changed, commented, comment
                          updated, comment deleted, issue link created, issue link deleted{' '}
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={CheckCircleSvg} alt='Save, reuse, share, and manage filters' />
                          Worklogs: created, updated, deleted
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={CheckCircleSvg} alt='Save, reuse, share, and manage filters' />
                          Projects: created, updated, deleted
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={CheckCircleSvg} alt='Save, reuse, share, and manage filters' />
                          Users: created, updated, deleted
                        </div>
                      </li>
                      <li>
                        <div className='li-item'>
                          <img src={CheckCircleSvg} alt='Save, reuse, share, and manage filters' />
                          Global features enabled/disabled
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className='li-item'>
                      <img src={CheckCircleSvg} alt='Save, reuse, share, and manage filters' />
                      View detailed user activities in issues, including old and new values.{' '}
                    </div>
                  </li>
                </ul>
              </div>
              <div className='doc-button'>
                <Button
                  appearance='default'
                  iconAfter={<OpenIcon size='medium' />}
                  onClick={() => {
                    const newWindow = window.open(
                      `https://twinit.atlassian.net/wiki/spaces/UAAL/overview`,
                      '_blank',
                      'noopener,noreferrer'
                    );
                    if (newWindow) newWindow.opener = null;
                  }}
                >
                  Read full documentation
                </Button>
                <Button
                  appearance='primary'
                  iconAfter={<OpenIcon size='medium' />}
                  onClick={() => {
                    window.AP.navigator.go('site', {
                      relativeUrl:
                        '/plugins/servlet/ac/twinit-reports-app/twinit-notifications-navigation-bar',
                    });
                  }}
                >
                  Start using the App
                </Button>
              </div>
            </div>
          </div>
          <div className='app-section'>
            <div className='app-section-title'>
              <h1>Support</h1>
            </div>
            <div className='app-section-body'>
              <span className='app-section-description'>
                If you have any trouble configuring your use case or find any bugs, please contact
                us at:{' '}
                <a
                  className='sc-dNLxif kFXRWW'
                  title='mailto:contact@twinit.ge'
                  href='mailto:contact@twinit.ge'
                  target='_blank'
                  data-renderer-mark='true'
                  rel='noreferrer'
                >
                  contact@twinit.ge
                </a>{' '}
                or raise an issue on our{' '}
                <a
                  target='_blank'
                  className='sc-jqCOkK hdFCoC'
                  title='https://twinit.atlassian.net/servicedesk/customer/portal/3/group/17/create/516'
                  href='https://twinit.atlassian.net/servicedesk/customer/portal/3/group/17/create/516'
                  data-renderer-mark='true'
                  rel='noreferrer'
                >
                  Service Desk
                </a>
              </span>
              <span className='app-section-description'>
                We are happy to know what you are missing in the User Activity Audit Log so we add
                the new features.
              </span>
              <span className='app-section-description'>
                Contact us at: contact@twinit.ge or raise an issue on our
                <a
                  target='_blank'
                  className='sc-jqCOkK hdFCoC'
                  title='https://twinit.atlassian.net/servicedesk/customer/portal/3/group/17/create/516'
                  href='https://twinit.atlassian.net/servicedesk/customer/portal/3/group/17/create/516'
                  data-renderer-mark='true'
                  rel='noreferrer'
                >
                  {' '}
                  Service Desk
                </a>
                .
              </span>
            </div>
          </div>
        </div>
        <div className='footer'>
          <div className='footer-item'>
            <a
              target='_blank'
              href='https://marketplace.atlassian.com/apps/1230734/user-activity-audit-log?tab=versions&hosting=cloud'
              className='header-button'
              rel='noreferrer'
            >
              <span>Version: 1.0.0</span>
            </a>
            <a
              target='_blank'
              href='https://twinit.atlassian.net/wiki/spaces/UAAL/overview'
              className='header-button'
              rel='noreferrer'
            >
              <span>Documentation</span>
            </a>
            <a
              target='_blank'
              href='https://twinit.ge/eula'
              className='header-button'
              rel='noreferrer'
            >
              <span>EULA</span>
            </a>
          </div>
          <div className='footer-item'>
            <a
              target='_blank'
              href='https://marketplace.atlassian.com/vendors/1220298/'
              className='header-button'
              rel='noreferrer'
            >
              <span>Twinit’s presence on Atlassian Marketplace</span>
            </a>
            <a target='_blank' href='https://twinit.ge/' className='header-button' rel='noreferrer'>
              <span>twinit.ge</span>
            </a>
            <img src={FooterLogo}></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
